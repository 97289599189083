@use "./abstracts" as *;

.app-c-sub-header {
  padding: 16px 40px 0 40px;
  background-color: var(--blue-200);
  position: sticky;
  top: 64px;
  z-index: 8;

  @include breakpoint-down(md) {
    padding: 16px 16px 0 16px;
    top: 95px;
  }
    @include breakpoint-down(lg) {
      @include orientation(landscape) {
        position: relative;
        top: 0;
      }
    }
}

.app-c-breadcrumb {
  @extend %flex-vert-center;
  gap: 8px;
  color: var(--text-500);
  font-size: $text-sm;

  .app-c-go-back {
    @extend %inline-flex-all-center;
    margin-right: 8px;
    height: 20px;
    width: 20px;
    color: var(--black);
  }

  a {
    text-decoration: none;
    color: var(--text-500);
    font-weight: var(--font-weight-semi-bold);
  }
}

.app-c-sub-header-bottom {
  width: 100%;
  @extend %flex-vert-end;
  justify-content: space-between;
  padding-top: 16px;
  @include breakpoint-down(xl) {
      @include orientation(landscape) {
        padding-top: 8px;
      }
    }

  @include breakpoint-down(md) {
    padding-top: 12px;
  }
}

.app-c-sub-header-title {
  h3 {
    @include breakpoint-down(xl) {
        @include orientation(landscape) {
          font-size: $text-xl;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 550px;
        }
      }
    @include breakpoint-down(md) {
      font-size: $text-xl;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 250px;
    }
  }
}

.app-c-upper-title {
  font-size: $text-xl;
  line-height: 1.38;
}

.app-c-tab-wrapper {
  @extend %flex-grow-full;
  position: relative;
  // margin-top: -58px;
  padding: 20px 0 0;
  @include breakpoint-down(xl) {
      @include orientation(landscape) {
        padding: 16px 0 0;
      }
    }

  @include breakpoint-down(md) {
    padding: 16px 0 0;
  }
  @media only screen and (max-height: 768px) {
    padding: 12px 0 0;
  }
}

.app-c-tab-header {
  ul {
    @extend %flex-horiz-away;
    gap: 8px;

    li {
      @extend %flex-vert-center-start;
      flex: 1;
      padding: 10px 24px;
      background-color: var(--gray-600);
      border-radius: 6px 6px 0 0;
      color: var(--white);
      min-height: 58px;
      gap: 8px;
      cursor: pointer;
      @include breakpoint-down(xl) {
          @include orientation(landscape) {
            padding: 10px 12px;
              min-height: 48px;
          }
        }

      @include breakpoint-down(md) {
        padding: 10px 16px;
        min-height: 48px;
        justify-content: center;
      }

      &.active {
        background-color: var(--white);
        color: var(--blue-900);

        .app-c-tab-step {
          opacity: 0.6;
        }

        .app-c-tab-icon {
          background-color: transparent;
          box-shadow: none;
          border: 1px solid var(--blue-900);
        }

        .icon-timer {
          display: none;
        }

        .icon-light {
          display: block;
        }
      }

      &.done {
        .app-c-tab-icon {
          background-color: var(--green-600);
          border-color: transparent;
        }

        .icon-timer,
        .icon-light {
          display: none;
        }

        .icon-tick-white {
          display: block;
        }
      }

      .icon-light,
      .icon-tick-white {
        display: none;
      }

      .app-c-tab-icon {
        @extend %flex-all-center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        box-shadow: 0px 0px 4px 0px #0000001f;
        background-color: var(--blue-400);
      }

      .app-c-tab-text {
        font-weight: var(--font-weight-semi-bold);
        @extend %flex-col;
        justify-content: center;
      }

      .app-c-tab-step {
        line-height: 1.583;
        font-size: $text-sm;

        @include breakpoint-down(md) {
          font-size: $text-md;
        }
      }

      .app-c-tab-label {
        line-height: 1.357;
        font-size: $text-md;
        @include breakpoint-down(xl) {
            @include orientation(landscape) {
              display: none;
            }
          }

        @include breakpoint-down(md) {
          display: none;
        }
      }
    }
  }
}

.app-c-tab-content {
  @extend %flex-grow-full;
}

.app-c-tab-content-wrapper {
  @extend %flex-grow-full;
  padding: 26px 40px 0 40px;
  @include breakpoint-down(xl) {
      @include orientation(landscape) {
        padding: 16px 40px 0 40px;
      }
    }

  @include breakpoint-down(md) {
    padding: 16px 16px 0 16px;
  }
  @media only screen and (max-height: 768px) {
    padding: 16px 40px 0 40px;
  }

  .app-c-tab-heading {
    display: none;
    @include breakpoint-down(xl) {
        @include orientation(landscape) {
        font-size: $text-lg;
          display: block;
          margin-bottom: 6px;
        }
      }

    @include breakpoint-down(md) {
      font-size: $text-lg;
      display: block;
      margin-bottom: 6px;
    }
  }
}

.app-c-v-tab-wrapper {
  @extend %flex;
  background-color: var(--white);
  border: 1px solid var(--gray-200);
  // border-bottom: 0 none;
  border-radius: 6px;
  box-shadow: 0px 0px 16px 3px #00000014;
  flex-grow: 1;
  min-height: 0;
  height: calc(100vh - 383px);
  @include breakpoint-down(xxl) {
      height: calc(100vh - 400px);
    }
        @include orientation(landscape) {
          @include breakpoint-down(lg) {
          height: auto;
          }
        }
}

.app-c-v-tab-sidebar {
  border-right: 1px solid var(--gray-200);
  padding: 43px 0;
  background-color: var(--gray-100);
  width: 164px;
  border-radius: 6px 0 0 6px;
  overflow-y: auto;
  overflow-x: hidden;
  @include breakpoint-down(xxl) {
    @include orientation(landscape) {
    padding: 16px 0;
    }
  }
  @include breakpoint-down(xl) {
      @include orientation(landscape) {
        padding: 24px 0;
      }
    }

  @include breakpoint-down(md) {
    padding: 24px 0;
    width: 140px;
  }
  @media only screen and (max-height: 768px) {
    padding: 8px 0;
  }

  >ul {
    >li {
      display: flex;
      flex-direction: column;
      word-wrap: break-word;
      justify-content: center;
      gap: 10px;
      height: 78px;
      padding: 16px 16px 16px 20px;
      font-size: $text-sm;
      font-weight: var(--font-weight-bold);
      line-height: 1.583;
      color: var(--blue-900);
      cursor: pointer;
      transition: all ease 0.3s;
      position: relative;
      @include breakpoint-down(xxl) {
        @include orientation(landscape) {
          padding: 8px 16px 8px 20px;
          height: 68px;
          line-height: 1.3;
        }
        }

      @include breakpoint-down(md) {
        padding: 16px 12px 16px 12px;
      }

      &::after {
        position: absolute;
        height: 0;
        width: 4px;
        background-color: var(--yellow-500);
        content: "";
        right: -1px;
        bottom: 0;
        top: 0;
        margin: auto;
        transition: all ease 0.3s;
      }

      &.active {
        background-color: var(--white);

        &::after {
          height: 100%;
        }
      }

      &:hover {
        color: var(--blue-300);
      }
    }
  }
}

.app-c-v-tab-content-holder {
  @extend %flex-grow-overflow;
}
.app-c-v-tab-content{
  @extend %flex-grow-full;
  height: 100%;
}
.app-l-footer {
  @extend %flex-vert-center;
  justify-content: flex-end;
  gap: 16px;
  padding: 22px 40px;
  border-top: 1px solid var(--gray-300);
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: var(--white);
  z-index: 7;
  @include breakpoint-down(xl) {
      @include orientation(landscape) {
        padding: 16px 16px;
      }
    }
  @include breakpoint-down(md) {
    padding: 16px 16px;
    gap: 8px;
  }
    @media only screen and (max-height: 768px) {
      padding: 8px 16px;
    }

  .app-c-btn {
    min-width: 120px;

    @include breakpoint-down(md) {
      min-width: 100px;
    }
  }
}

.disply-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;

  @include breakpoint-down(md) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 8px;
  }
}

.disply-grid-item {
  @extend %flex-col;
  gap: 2px;
  padding: 10px 0;

  @include breakpoint-down(md) {
    padding: 8px 0;
  }

  .disply-grid-item-key {
    font-size: $text-sm;
    line-height: 1.2;
    color: var(--gray-800);
    line-height: 1.361;
  }

  .disply-grid-item-value {
    font-size: $text-md;
    font-weight: var(--font-weight-semi-bold);
    line-height: 1.362;
    overflow-wrap: anywhere;
    @include breakpoint-down(md) {
      font-size: $text-sm-plus;
    }
  }
}

.app-l-form-blocks {
  padding: 20px;
  border-top: 1px solid var(--gray-400);

  // &:first-child {
  //   border-top: 0 none;
  // }
  @include breakpoint-down(md) {
    padding: 12px;
  }
}

.app-l-form-label {
  @extend %inline-flex-vert-center;
  gap: 8px;
  margin: 0 0 10px 0;
  font-size: $text-md;
  line-height: 1.362;
  color: var(--gray-800);
  overflow-wrap: anywhere;
  @include breakpoint-down(md) {
    font-size: $text-sm-plus;
  }

  .icon-done {
    @extend %flex-all-center;
    position: relative;
    top: 2px;
    border-radius: 50%;
    width: 1.8rem;
    height: 1.8rem;
    color: var(--green-600);

    &.gray {
      color: var(--gray-500);
    }
  }
}

.app-l-form-elements {
  @extend %flex-col;
  gap: 8px;
  padding: 0 22px;
  @include breakpoint-down(md) {
    padding: 0;
  }
}

.view-only-section {
  padding: 0 16px;
  @include breakpoint-down(md) {
    padding: 0;
  }

  .view-only-data {
    font-size: $text-md;
    line-height: 1.362;
    @include breakpoint-down(md) {
      font-size: $text-sm-plus;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h5 {
    margin-top: 12px;
    line-height: 1.362;
  }
}

.app-l-search-block {
  @extend %flex-horiz-away-vert-center;
  gap: 16px;
  flex-wrap: nowrap;
  align-items: flex-start;

  @include breakpoint-down(md) {
    gap: 8px;
  }
}

.app-c-filter-btn {
  @extend %flex-all-center;
  width: 56px;
  height: 56px;
  border-radius: 6px;
  background-color: var(--white);
  color: var(--primary-dark);
  border: 1px solid var(--primary-dark);
  cursor: pointer;

  @include breakpoint-down(md) {
    width: 48px;
    height: 48px;
  }

  &:hover {
    color: var(--blue-600);
    border-color: var(--blue-600);
  }

  &.active {
    background-color: var(--blue-75);
  }

  &[disabled],
  &:disabled {
    color: var(--blue-400);
    border-color: var(--blue-400);
    cursor: default;
  }
}

.app-c-filter-wrapper {
  position: relative;
}

.app-c-filter-dropdown {
  width: 225px;
  height: 168px;
  border-radius: 6px;
  background-color: var(--white);
  box-shadow: 0px 2px 14px 0px #5f7da652;
  border: 1px solid var(--gray-250);
  position: absolute;
  right: 0;
  transform: translate(0, 18px);
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  transition: all ease 0.3s;

  &.active {
    transform: translate(0, 8px);
    pointer-events: auto;
    opacity: 1;
  }
}

.app-c-filter-header {
  border-bottom: 1px solid var(--gray-250);
  position: relative;
  padding: 12px 16px;
}

.app-c-filter-title {
  font-size: $text-md;
  line-height: 1.2;
  font-weight: var(--font-weight-semi-bold);
}

.app-c-filter-close {
  @extend %flex-all-center;
  background-color: transparent;
  padding: 0;
  border: none;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;

  &:hover {
    color: var(--black);
  }
}

.app-c-filter-list-item {
  padding: 12px 8px;

  &:hover {
    background-color: var(--gray-100);
  }
}

.app-l-radio-group {
  @extend %flex;
  gap: 32px;
}

.view-image-stack {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 8px 0 0 0;
  gap: 8px;

  .view-image-wrapper {
    overflow: hidden;
    border-radius: 10px;
    gap: 8px;
    position: relative;
    aspect-ratio: 1/1;
    background-color: var(--gray-250);
    @include breakpoint-down(md) {
      width: 60px;
    }

    &:hover {
      &::after {
        background: rgba(0, 65, 107, 0.8);
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: block;
      background: rgba(0, 0, 0, 0.48);
      position: absolute;
      z-index: 1;
      transition: background ease 0.3s;
    }

    .view-image {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .view-image-actions {
      position: absolute;
      margin: auto;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 24px;
      height: 24px;
      @extend %flex-all-center;
      color: var(--white);
      z-index: 2;
      transition: transform ease 0.3s;

      &:hover {
        transform: scale(1.2);
      }
    }
  }
}