@use './abstracts' as *;

*,
*::before,
*::after {
  box-sizing: border-box;
  font: inherit;
}

html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: var(--body-font);
  vertical-align: baseline;

  @extend %touch-tap-none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-900);
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
h1,h2,h3,h4,h5,h6{
  font-weight: var(--font-weight-bold);
  color: var(--black);
  line-height: 1.361;
  margin: 0;
  overflow-wrap: break-word;
}
h1{
  
}
h2{
  font-size: $text-xxl-small-plus;
  line-height: 1.153;
}
h3{
  font-size: 2.6rem;
}
h4{
  font-size: $text-xxl;
}
h5{
  font-size: $text-md;
}
p{
  overflow-wrap: anywhere;
}
// Input placeholder
::-ms-input-placeholder {
  color: var(--text-200);
}

::placeholder {
  color: var(--text-200);
}