@use "./abstracts" as *;

.toast-container {
  border-radius: 6px;
  padding: 4px;
  .ngx-toastr {
    padding: 12px 17px 12px 43px;
    border-radius: 6px;
    background-position: 9px 20px;
    background-size: 26px;
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
  .toast-close-button {
    font-weight: var(--font-weight-regular);
    font-size: $text-xxl-small;
    text-shadow: none;
  }
  .toast-success {
    background-color: var(--green-150);
    border: 1px solid var(--green-300);
    box-shadow: none;
    background-image: url("../assets/images/check-circle.png");
    .toast-close-button {
      color: var(--green-700);
    }
    .toast-content {
      color: var(--green-700);
    }
    .toast-header {
      color: var(--green-700);
    }
  }
  .toast-warning {
    background-color: var(--yellow-300);
    border: 1px solid var(--yellow-350);
    box-shadow: none;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAALySURBVHgBrVbdcdpAEN6901vyoAZipAqsiSGTt9gd2BUYNxCiCgwVyFRgpQInFZjHTPwTXAGCBsJDHtFt9nQ6IRgkJIZvhkGnu9vvdm/17SK0wCwKXAAnkKC8FHAJ2W819cPpsqkNbEDiCeFcI1EfEL1dawgoQYJJSumIyRM4hFB7I0DeocBraAOiuI4Yd5N1z6WgB552S6+XBPgTFE0RVBZCAuGCwICNfOGRV3Cyx0pB6IcvP/YSzsefrtlgvN6MiVB08yF8nkANFlGvDwJuN4lFvzP4/b2ScBadXUqBD6WTjjuDl2/QAvNxd8hGb+04VXDhlw6La7LAk0I+8ivPkMGoM3gewgHYIl2mauXbTBZ2ESfIsCkZG7ydR93Keb1XRycfuhJlZOcyD413zsyQYdIZPPlVxsphRw5X3d0uxr2ZvdPcy0SsvTPg7BpBLdIJUyX6YCtwktqVnGz2WYDTzw5pTnI20+Hc590hWIy7f/mPPy9KTgYvvjByhV7GjjCBI4MUf7sZ0DNiwtpYTKb01sSIFoZZ1AugEWhqnxzmYkIq1MQqSD1ZL5ACHlmJ/mjifevLNolFX8gSYRNISIP1s/KgBXSFESmIZH0C0Yq8Cco2HU2o65l9gYincHRQEZEVcwktOawKc8NIl3BkoNCVxAiK5jLSpjDO590midAUi+hjf/uTywgVOLFdJATdQ0OYNqMGAtdVI5WjgtAPf2mpyuoW8onmd92oysZJ+Bprcde/XQXWQgu89Y7dizWHsZ9Dq0Au4FlWscEhq/4eXa0mY8NDYwe5+ssLS1iUJ32hXCyv7FhvMKdsScbRsWQaSmFoyXK7m2Al6cvSPZr+hDh8rzHUIO+D7oswMlLEG//r08a+iiZK1zwRbTdGuhUkojfelDdR4CLKUy6Ml9sNF9fKq121sqZN/Mz9aMqtArVrEzlB0vQdh3GyM4MbNMKWeLMVLMMIB8ZKOXH5vg4i3CQ/dx34F1Am+OQi6/AK3k+rvNmF/0csY63HP6ljAAAAAElFTkSuQmCC");
    color: var(--yellow-700);
    .toast-header {
      color: var(--yellow-700);
    }
    .toast-close-button {
      color: var(--yellow-700);
    }
  }
  .toast-error {
    background-color: var(--red-100);
    border: 1px solid var(--red-300);
    box-shadow: none;
    background-image: url("../assets/images/error-circle.png");
    color: var(--red-400);
    .toast-header {
      color: var(--red-400);
    }
    .toast-close-button {
      color: var(--red-400);
    }
  }
  .toast-title {
    display: none;
  }
  .toast-header {
    font-size: $text-lg;
    font-weight: var(--font-weight-bold);
  }
  .toast-content {
    font-size: $text-sm-plus;
    font-weight: var(--font-weight-semi-bold);
    line-height: 1.106;
    padding-top: 4px;
  }
}
