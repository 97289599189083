@use 'abstracts' as *;
@use 'reset';
@use 'toast.scss';
@use 'layout.scss';

html,
body {
    height: 100%;
}

.app-c-header {
    background-color: var(--ba-primary-red);
    padding: 10px 0;
}