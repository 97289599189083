@use './palette' as *;
@use './breakpoints' as *;
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}
%flex {
  display: flex;
}
%flex-inline {
  display: inline-flex;
}
%inline-flex-all-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

%inline-flex-vert-center {
  display: inline-flex;
  align-items: center;
}

%flex-vert-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

%flex-col {
  display: flex;
  flex-direction: column;
}
%flex-col-reverse {
  display: flex;
  flex-direction: column-reverse;
}
%flex-row {
  display: flex;
  flex-direction: row;
}
%inline-flex-horiz-away-vert-center {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

%flex-all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
%flex-all-center-col {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

%flex-horiz-center {
  display: flex;
  justify-content: center;
}

%flex-horiz-center-col {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

%flex-vert-center {
  display: flex;
  align-items: center;
}

%flex-vert-start {
  display: flex;
  align-items: flex-start;
}

%flex-vert-end {
  display: flex;
  align-items: flex-end;
}

%flex-vert-center-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

%flex-horiz-away-vert-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

%flex-horiz-away {
  display: flex;
  justify-content: space-between;
}

%flex-horiz-end-vert-end {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
%flex-horiz-end-vert-center {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

%flex-wrap {
  flex-wrap: wrap;
}

%flex-horiz-start-vert-center {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
%flex-grow-full {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
%flex-grow-overflow {
  @extend %flex-col;
  height: 100%;
  flex: 1 1 1px;
  overflow-y: auto;
}

%flex-stretch-full {
  display: flex;
  align-items: stretch;
}
%flex-align-self-end {
  display: flex;
  align-self: flex-end;
}

@mixin flex-direction($value: row) {
  -moz-flex-direction: $value;
  flex-direction: $value;
}

%ie11-min-h-flex-center-fix {
  content: '';
  display: block;
  min-height: inherit;
  font-size: 0;
}

%user-select-none {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

%touch-tap-none {
  -webkit-tap-highlight-color: transparent;
}

%absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
%truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

