:root {
    /* open-sans-300 - latin */
    @font-face {
      font-display: swap;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300;
      src: url('../../assets/fonts/open-sans/open-sans-v40-latin-300.woff2') format('woff2');
      /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
  
    /* open-sans-regular - latin */
    @font-face {
      font-display: swap;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      src: url('../../assets/fonts/open-sans/open-sans-v40-latin-regular.woff2') format('woff2');
      /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
  
    /* open-sans-500 - latin */
    @font-face {
      font-display: swap;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 500;
      src: url('../../assets/fonts/open-sans/open-sans-v40-latin-500.woff2') format('woff2');
      /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
  
    /* open-sans-600 - latin */
    @font-face {
      font-display: swap;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      src: url('../../assets/fonts/open-sans/open-sans-v40-latin-600.woff2') format('woff2');
      /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
  
    /* open-sans-700 - latin */
    @font-face {
      font-display: swap;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      src: url('../../assets/fonts/open-sans/open-sans-v40-latin-700.woff2') format('woff2');
      /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    }
  
    /* open-sans-800 - latin */
    @font-face {
      font-display: swap;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 800;
      src: url('../../assets/fonts/open-sans/open-sans-v40-latin-800.woff2') format('woff2');
    }

    
  --font-primary: "Open Sans", sans-serif;
  --body-font: var(--font-primary);

  /* font-weight */
  --font-weight-light: 200;
  --font-weight-thin: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
}
// Font sizes
$text-xs: 1rem; // 10px
$text-xs-plus: ($text-xs + 0.1); // 11px
$text-sm: ($text-xs + 0.2); // 12px
$text-sm-plus: ($text-xs + 0.3); // 13px
$text-md: ($text-xs + 0.4); // 14px
$text-md-plus: ($text-xs + 0.5); // 15px
$text-lg: ($text-xs + 0.6); // 16px
$text-lg-plus: ($text-xs + 0.7); // 17px
$text-xl: ($text-xs + 0.8); // 18px
$text-xl-plus: ($text-xs + 0.9); // 19px
$text-xxl: ($text-xs + 1); // 20px
$text-xxl-small: ($text-md + 0.8); // 22px
$text-xxl-small-plus: ($text-xxl + 0.6); // 24px
$text-xxl-plus: ($text-xs + 2); // 30px
$text-xxl-large: ($text-xs + 2.6); // 36px
