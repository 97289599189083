@use "./palette" as *;


:root {
  // Colors
  @each $color, $shades in $colors {
    @each $shade, $value in $shades {
      --#{"" + $color}-#{$shade}: #{$value};
    }
  }
  // Generic color
  @each $color, $values in $generic-colors {
    --#{"" + $color}: #{$values};
  }

  --stroke-width:1.5;
}

