$breakpoint-up: (
  xs: 321px,
  sm: 577px,
  md: 768px,
  lg: 992px,
  xl: 1201px,
  xxl: 1401px,
  xxxl: 1601px,
);

$breakpoint-down: (
  xs: 320px,
  sm: 576px,
  md: 767px,
  lg: 991px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

$xs: 390px;
$sm: 576px;
$md: 860px;
$lg: 991px;
$xl: 1200px;
$xxl: 1440px;
$xxxl: 1600px;

$media-down: (
  xs: $xs,
  sm: $sm,
  md: $md,
  lg: $lg,
  xl: $xl,
  xxl: $xxl,
  xxxl: $xxxl,
);
$media-up: (
  xs: $xs + 1,
  sm: $sm + 1,
  md: $md + 1,
  lg: $lg + 1,
  xl: $xl + 1,
  xxl: $xxl + 1,
  xxxl: $xxxl + 1,
);

@mixin breakpoint-up($size) {
  @media (min-width: map-get($breakpoint-up, $size)) {
    @content;
  }
}

@mixin breakpoint-down($size) {
  @media (max-width: map-get($breakpoint-down, $size)) {
    @content;
  }
}

@mixin media-down($size) {
  @media (max-width: map-get($media-down, $size)) {
    @content;
  }
}
@mixin media-up($size) {
  @media (min-width: map-get($media-up, $size)) {
    @content;
  }
}

@mixin orientation($direction) {
  $orientation-landscape: '(orientation:landscape)';
  $orientation-portrait: '(orientation:portrait)';

  @if $direction == landscape {
    @media #{$orientation-landscape} {
      @content;
    }
  }
  @if $direction == portrait {
    @media #{$orientation-portrait} {
      @content;
    }
  }
}
